import { memo, MutableRefObject, useEffect, useRef } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import { gsap } from 'gsap';

import styles from './Footer.module.scss';

import sanitizer from '@/utils/sanitizer';
import useIntersectionObserver from '@/hooks/use-intersection-observer';
import { FooterProps } from '@/types';

import { DropdownSVG } from '@/assets/svgs';

import Newsletter, { NewsletterHandle } from '../Newsletter/Newsletter';
import ThemedButton, { ButtonType } from '../ThemedButton/ThemedButton';

function Footer({ className, acfOptions, settings }: FooterProps) {
  const footerInfo = acfOptions?.footerSettings;
  const externalLinks = footerInfo?.externalLinks;
  const socialLinks = footerInfo?.socialMedia;
  const refFooter = useRef() as MutableRefObject<HTMLDivElement>;
  const footerTL = useRef() as MutableRefObject<GSAPTimeline>;
  const newsletterRef = useRef() as MutableRefObject<NewsletterHandle>;
  const footerVisible = useIntersectionObserver(refFooter);

  useEffect(() => {
    const q = gsap.utils.selector(refFooter);
    const mainWrapper = q('wrapper');

    gsap.set(mainWrapper, { autoAlpha: 0 });

    footerTL.current = gsap
      .timeline({ paused: true })
      .set(mainWrapper, { autoAlpha: 1 })
      .fadeInFrom(q('.company'))
      .add(newsletterRef.current?.animateIn())
      .from(
        q('.extraLink'),
        { autoAlpha: 0, duration: 0.568, ease: 'linear', stagger: 0.3 },
        '-=0.6'
      );
  }, []);

  useEffect(() => {
    if (footerVisible) {
      footerTL.current.play();
    }
  }, [footerVisible]);

  return (
    <footer className={classnames(styles.Footer, className)} ref={refFooter}>
      <div className={classnames(styles.wrapper, 'wrapper')}>
        <div className={styles.footerTop}>
          <div className={classnames(styles.companyName, 'company')}>
            <div className={styles.title}>{settings?.generalSettingsTitle}</div>
            <div className={styles.eclipse} />
          </div>
          <div className={styles.newsletterWrapper}>
            <Newsletter
              title={footerInfo?.newsletterTitle}
              light={true}
              ref={newsletterRef}
              animateScroll={false}
            />
          </div>
        </div>
        <div className={classnames(styles.footerMiddle, 'extraLink')}>
          <div className={styles.contactInfo}>
            {footerInfo?.address && (
              <Link href={footerInfo?.addressLink}>
                <a
                  aria-label="address-link"
                  target="_blank"
                  dangerouslySetInnerHTML={{ __html: sanitizer(footerInfo?.address) }}
                  className={styles.address}
                ></a>
              </Link>
            )}
            <Link href={`tel:${footerInfo?.phoneNumber}`}>
              <a aria-label="tel-link" className={styles.phone}>
                {footerInfo?.phoneNumber}
              </a>
            </Link>
            <ThemedButton
              theme={ButtonType.Action}
              url={`mailto:${footerInfo?.email}`}
              ariaLabel="email-link"
              light={true}
              text={footerInfo?.email}
              j3IsInternalURL={true}
            ></ThemedButton>
          </div>
          <div className={styles.menuLinks}>
            <ul className={styles.socialLinks}>
              {socialLinks?.map((link) => (
                <li key={`${link?.mediaLabel}-menu`}>
                  <ThemedButton
                    theme={ButtonType.Action}
                    url={link?.mediaUrl}
                    ariaLabel={`${link?.mediaLabel}-menu`}
                    light={true}
                    text={link?.mediaLabel}
                  />
                </li>
              ))}
            </ul>
            <ul className={styles.externalLinks}>
              {externalLinks?.map((link) => (
                <li key={`${link?.label}-menu`}>
                  <ThemedButton
                    theme={ButtonType.Icon}
                    url={link?.url}
                    text={link?.label}
                    ariaLabel={`${link?.label}-menu`}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classnames(styles.footerBottom, 'extraLink')}>
          <div className={styles.left}>
            <div className={styles.inner}>
              <span dangerouslySetInnerHTML={{ __html: sanitizer('&copy;') }} />
              {new Date().getFullYear()} Eclipse
            </div>
            <button
              className={styles.backToTop}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              }}
            >
              Back To Top
              <span className={styles.circle}>
                <DropdownSVG className={styles.svg} />
              </span>
            </button>
          </div>
          <ul className={styles.links}>
            {footerInfo?.links?.map((link) => (
              <li key={`${link?.label}-menu`}>
                <ThemedButton
                  theme={ButtonType.Action}
                  url={link?.url}
                  text={link?.label}
                  light={true}
                  ariaLabel={`${link?.label}-menu`}
                  j3IsInternalURL={true}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default memo(Footer);
